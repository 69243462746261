import React, { Component } from 'react'
import './css/07.css'

export default class App extends Component {
    state = {//设置状态
        mytext: "收藏",
        text: "123",
        myshow: false,
        list: [],
        list_json: {},
        is_json: false
    }
    newref = React.createRef()//设置ref

    componentDidMount() {//绑定按键
        document.addEventListener('keydown', this.handleKeyPress)
    }

    componentWillUnmount() {//解绑按键
        document.removeEventListener('keydown', this.handleKeyPress)
    }

    handleKeyPress = (event) => {//按键触发
        if (event.key === 'Enter') {//回车
            this.handleButtonClick()//执行函数
        }
    }

    handleButtonClick = () => {//函数
        if (this.state.is_json) {
            this.setState({
                myshow: !this.state.myshow
            })
            var input_value = this.newref.current.value
            console.log(input_value, this.state.is_json)
            var key = `${Date.now()}${getRandomString(10)}`
            this.state.list_json[key] = input_value
            console.log(key)
            // this.setState()
            this.newref.current.value = ""
        } else {
            this.setState({
                myshow: !this.state.myshow
            })
            var input_value = this.newref.current.value
            console.log(input_value, this.state.is_json)
            this.state.list.unshift(input_value)
            // this.setState()
            this.newref.current.value = ""
        }
    }

    render() {
        return (
            <div>
                <h1>欢迎来到react开发</h1>
                <h1>{this.state.text}</h1>
                <input ref={this.newref}></input>
                <button onClick={this.handleButtonClick}>
                    收藏
                </button>
                <button onClick={() => {
                    this.setState({
                        is_json: !this.state.is_json
                    })
                }}>{this.state.is_json ? "切换list" : "切换json"}</button>
                <br></br>
                list
                <ul>
                    {
                        this.state.list.map(item =>
                            <li key={`${Date.now()}${getRandomString(10)}`}>{`${item}`}</li>
                        )
                    }
                </ul>
                <br></br>
                json
                <div>
                    {/* json解析 */}
                    {Object.keys(this.state.list_json).length === 0 ? <p className='text1'>没有了</p> : null}
                    {
                        Object.entries(this.state.list_json).map(([key, value]) =>
                            <p className='text' key={key} onClick={() => {
                                console.log("删除", key, this.state.list_json)
                                delete this.state.list_json[key]
                                this.setState({
                                    list_json: this.state.list_json
                                })
                            }}>{`${value},key:${key}`}</p>)
                    }
                </div>
            </div>
        )
    }
}

function getRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
    }
    return result;
}